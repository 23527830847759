<template>
  <v-container fluid>
    <v-tabs>
      <v-tab v-for="terminal in currentItem.terminals" :key="terminal.id">
        <div class="caption">{{ terminal.location }}</div>
      </v-tab>

      <v-tab-item v-for="terminal in currentItem.terminals" :key="terminal.id">
        <OnlineTerminal :terminal="terminal" />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import CentrifugeApi from '@/services/CentrifugeApi'

export default {
  name: 'SPOnline',

  components: {
    OnlineTerminal: () => import('@/components/common/online/OnlineTerminal'),
  },

  props: {
    currentItem: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  data: () => ({
    ws: null,
    terminalSubscriptions: [],
  }),

  computed: {
    currentCompanyId() {
      return +this.$route.params.id
    },
  },

  created() {
    this.initialize()
  },

  beforeDestroy() {
    this.disconnectFromWs()
  },

  methods: {
    async initialize() {
      if (!this.ws) this.connectToWs()
    },
    async connectToWs() {
      // *** Initialize the Centrifuge connection
      this.ws = new CentrifugeApi()
      await this.ws.connectToCentrifuge()
      // *** Subscribe to terminal IMEIs
      this.terminalSubscriptions = this.currentItem.terminals.map((terminal) => {
        const subscription = this.ws.centrifuge.newSubscription(terminal.imei)
        subscription.on('publication', this.handlePublication)
        subscription.subscribe()
        return subscription
      })
    },
    disconnectFromWs() {
      // *** Unsubscribe and disconnect from Centrifuge
      this.terminalSubscriptions.forEach((subscription) => {
        if (subscription) subscription.unsubscribe()
      })
      this.ws.disconnectFromCentrifuge()
    },
    handlePublication(ctx) {
      // *** Handle the publication data for a terminal
      const currentTerminalImei = ctx.data.info.imei
      // console.log(currentTerminalImei)
      if (this.currentItem.terminals.length) {
        const currentTerminal = this.currentItem.terminals.find((terminal) => terminal.imei === currentTerminalImei)
        if (currentTerminal) {
          ctx.data.devices.sort((a, b) => a.number - b.number)
          this.$set(
            this.currentItem.terminals.find((terminal) => terminal.imei === currentTerminalImei),
            'state',
            ctx.data
          )
        }
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
